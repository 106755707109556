<template>
  <div id="app">
    <!-- 顶部信息条 -->
    <div class="appTopCss">
      <div class="appTopView">
        <div class="appTopa">大宁网络首页</div>
        <div class="appTopb" v-if="loginType == 2">
          <span @click="user()" class="appTopSpana">欢迎 {{ userData.phonenumber }}回来！ 个人中心 </span>
          <span @click="back()" class="appTopSpanb">退出</span>
        </div>
        <div class="appTopb" v-if="loginType == 1">
          <span @click="register()" class="appTopSpana">注册</span>
          <span @click="login()" class="appTopSpanb">登录</span>
        </div>
      </div>
    </div>
    <!-- 导航 -->
    <div class="navigationOne">
      <div class="oneView">
        <div @click="daning()" style="margin-right: 30px;"><img src="./assets/meilidaning.png" alt=""
            style="height: 40px;" /> </div>
        <div class="txt" @click="cut(index, item.genreId, item.genreName)"
          :style="{ color: stu == index ? 'red' : '', padding: '5px 5px' }" v-for="(item, index) in list" :key="index">
          {{ item.genreName }}
        </div>
      </div>
    </div>
    <!-- 首页 -->
    <div v-if="stu === 0 && collect == '导航页面' && homeType == 1">
      <div style="height: 400px;">
        <swiper class="banner" :options="swiperOption" ref="mySwiper">
          <swiper-slide v-for="(item, index) in lunboList" :key="index">
            <!-- <img :src="item.url" alt="" style="height: 400px;" /> -->
            <el-image style="width: 100%; height: 400px" :src="item.url" fit="cover"></el-image>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div>
      <div class="titCss">最新作品</div>
      <div class="topCss">
        <div class="topView">
          <div @click="viewWorks(item, item.worksId, item.worksDescribe)" v-for="(item, index) in newWorksData"
            :key="index" class="topItem">
            <div class="itema" style="">
              <!-- <img :src="item.worksImg" class="rightulliimg" alt="" mode="aspectFit"> -->
              <el-image style="width: 100%; height: 280px"
                :src="item.worksImg + '?x-oss-process=image/watermark,text_576O5Li95aSn5a6B,color_ffffff,t_100,size_60,g_center,image/resize,w_400/quality,q_80'"
                fit="cover"></el-image>
            </div>
            <div class="itemb" style="">{{ item.worksDescribe }}</div>
            <div class="itemc" style="">查看{{ item.worksBrowse }}</div>
          </div>
        </div>
      </div>
      <div class="titCss">推荐作品</div>
      <div class="topCss">
        <div class="topView">
          <div @click="viewWorks(item, item.worksId, item.worksDescribe)" v-for="(item, index) in recommendedWorksData"
            :key="index" class="topItem">
            <div class="itema" style="">
              <!-- <img :src="item.worksImg" class="rightulliimg" alt="" mode="aspectFit"> -->
              <el-image style="width: 100%; height: 280px" :src="item.worksImg" fit="cover"></el-image>
            </div>
            <div class="itemb" style="">{{ item.worksDescribe }}</div>
            <div class="itemc" style="">查看{{ item.worksBrowse }}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 首页作品详情 -->
    <div v-if="stu === 0 && collect == '导航页面' && homeType == 2">
      <!-- 二级导航 -->
      <div class="navigationTwo">
        <div class="twoTop">
          <div class="twoTopView">
            您现在的位置：
            <span>首页</span>
            <span style="margin: 0px 10px;">></span>
            <span>{{ title }}</span>
          </div>
        </div>
        <div class="twoBottom">
          <div class="twoBottomView">
            <span style="font-weight: 600;">分类</span>
            <span style="margin: 0px 20px;">></span>
            <span style="color: #FC8080;">全部</span>
          </div>
        </div>
      </div>
      <div class="threeCss">
        <div class="threeView">
          <div class="threeTop">
            <div style="">
              <img v-if="!detailsData.worksVideo"
                :src="detailsData.worksImg + '?x-oss-process=image/watermark,text_576O5Li95aSn5a6B,color_ffffff,t_60,size_100,g_center,image/resize,w_900/quality,q_80'"
                class="threeImg" alt="">
              <!-- 首页进入 -->

              <div class="input_video" @contextmenu.prevent>
                <video-player v-if="detailsData.worksVideo" id="check" class="video-player vjs-custom-skin"
                  ref="videoPlayer" :playsinline="true" :options="playerOptions"></video-player>
              </div>

            </div>
          </div>
          <div class="threeBottom">
            <div
              style="border-bottom: 1px solid #DCDBD9;width: 100%;text-align: start;padding-bottom: 20px;margin-bottom: 20px;">
              图片描述</div>
            <div
              style="background-color: #F6F6F6;padding: 10px;border: 1px solid #ECECEC;width: 100%;text-align: start;">
              {{ detailsData.worksDescribe }}</div>
          </div>
          <div class="threeCenter">
            <div
              style="border-bottom: 1px solid #DCDBD9;width: 100%;text-align: start;padding-bottom: 20px;margin-bottom: 20px;">
              其他推荐</div>
            <div>
              <div style="display: flex;align-items: center;">
                <div style="margin-right: 20px;" @click="viewWorks(item, item.worksId, item.worksDescribe)"
                  v-for="(item, index) in xiangsiList" :key="index">
                  <div style="width: 150px;height: 150px;">
                    <!-- <img :src="item.worksImg" style="width: 150px;height: 150px;" alt=""> -->
                    <el-image style="width: 150px; height: 150px" :src="item.worksImg" fit="cover"></el-image>
                  </div>
                  <div>{{ item.worksDescribe }}</div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
    <!-- 其他导航 -->
    <div v-if="stu !== 0 && collect == '导航页面'">
      <!-- 二级导航 -->
      <div class="navigationTwo">
        <div class="twoTop">
          <div class="twoTopView">
            您现在的位置：
            <span>首页</span>
            <span style="margin: 0px 10px;">></span>
            <span>{{ nameTitle }}</span>
            <span v-if="type == 2" style="margin: 0px 10px;">></span>
            <span v-if="type == 2">{{ title }}</span>
          </div>
        </div>
        <div class="twoBottom">
          <div class="twoBottomView">
            <span style="font-weight: 600;">分类</span>
            <span style="margin: 0px 20px;">></span>
            <span style="color: #FC8080;">全部</span>
          </div>
        </div>
      </div>
      <!-- 二级页面 -->
      <div class="topCss" v-if="type == 1">
        <div class="topView">
          <div @click="twoCut(item, item.worksId, item.worksDescribe)" v-for="(item, index) in fenleiWorksList"
            :key="index" class="topItem">
            <div class="itema" style="">
              <!-- <img :src="item.worksImg" class="rightulliimg" alt="" mode="aspectFit"> -->
              <el-image style="width: 100%; height: 280px"
                :src="item.worksImg + '?x-oss-process=image/watermark,text_576O5Li95aSn5a6B,color_ffffff,t_100,size_60,g_center,image/resize,w_400/quality,q_80'"
                fit="cover"></el-image>
            </div>
            <div class="itemb" style="">{{ item.worksDescribe }}</div>
            <div class="itemc" style="">查看{{ item.worksBrowse }}</div>
          </div>
        </div>
      </div>
      <!-- 三级页面 -->
      <div v-if="type == 2" class="threeCss">
        <div class="threeView">
          <div class="threeTop">
            <div style="">
              <!-- <img :src="detailsData.worksImg" class="threeImg" alt=""> -->
              <img v-if="!detailsData.worksVideo"
                :src="detailsData.worksImg + '?x-oss-process=image/watermark,text_576O5Li95aSn5a6B,color_ffffff,t_60,size_100,g_center,image/resize,w_900/quality,q_80'"
                class="threeImg" alt="">

              <!-- 
              <video-player v-if="detailsData.worksVideo" class="video-player vjs-custom-skin" ref="videoPlayer2"
                :playsinline="true" style="object-fit:fill" :options="playerOptions" :x5-video-player-fullscreen="true"
                >
              </video-player> -->
            </div>
          </div>
          <div class="threeBottom">
            <div
              style="border-bottom: 1px solid #DCDBD9;width: 100%;text-align: start;padding-bottom: 20px;margin-bottom: 20px;">
              图片描述</div>
            <div
              style="background-color: #F6F6F6;padding: 10px;border: 1px solid #ECECEC;width: 100%;text-align: start;">
              {{ detailsData.worksDescribe }}</div>
          </div>
          <div class="threeCenter">
            <div
              style="border-bottom: 1px solid #DCDBD9;width: 100%;text-align: start;padding-bottom: 20px;margin-bottom: 20px;">
              其他推荐</div>
            <div>
              <div style="display: flex;align-items: center;">
                <div style="margin-right: 20px;" @click="viewWorks(item, item.worksId, item.worksDescribe)"
                  v-for="(item, index) in xiangsiList" :key="index">
                  <div style="width: 150px;height: 150px;">
                    <el-image style="width: 150px; height: 150px" :src="item.worksImg" fit="cover"></el-image>
                  </div>
                  <div>{{ item.worksDescribe }}</div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
      <!-- 分页 -->
      <!-- <div class="bottomCss">
        <div>首页</div>
        <div>1</div>
        <div> 左 </div>
        <div>1</div>
        <div> 右 </div>
        <div>尾页</div>
      </div> -->
      <Pagination v-if="type == 1" :pageNo="pageNum" :pageSize="pageSize" :total="total" :continues="1"
        @getPageNo="getPageNo" />
    </div>
    <!-- 注册 -->
    <div class="zhuce" v-if="collect == '注册'">
      <div class="zhuceCss">
        <div class="zhuceLeft">
          <div style="margin-top: 30px;"> <img src="./assets/logo.png"
              style="width: 80px;height: 80px;border-radius: 50px;background-color: #BCC1C7;" alt="" mode="aspectFit">
          </div>
          <div style="margin-top: 50px;color: #FBB0B1;">欢迎回来</div>
          <!-- <div style="margin-top: 50px;color: #ffffff;">信息中心</div>
          <div style="margin-top: 50px;color: #ffffff;">我的收藏</div>
          <div style="margin-top: 50px;color: #ffffff;">安全信息</div> -->
        </div>
        <div class="zhuceRight">
          <div style="width: 500px;margin-bottom: 150px;">
            <div style="margin-bottom: 20px;font-size: 34px;">用户注册</div>
            <div style="display: flex;align-items: center;justify-content: flex-end;margin-bottom: 20px;">
              <div>手机号码：</div>
              <div>
                <el-input placeholder="请输入手机号码" v-model="registerData.phone" clearable>
                </el-input>
              </div>
            </div>
            <div style="display: flex;align-items: center;justify-content: flex-end;margin-bottom: 20px;">
              <div>验证码：</div>
              <div style="position: relative;">
                <el-input placeholder="请输入验证码" v-model="registerData.code" clearable></el-input>
                <span class="fasong" @click="fasongCli()" v-if="isSend">
                  发送
                </span>
                <span class="fasong2" v-if="!isSend">
                  {{ sendmsg }}
                </span>
              </div>

            </div>
            <div style="display: flex;align-items: center;justify-content: flex-end;margin-bottom: 20px;">
              <div>昵称：</div>
              <div>
                <el-input placeholder="请输入昵称" v-model="registerData.nickName" clearable>
                </el-input>
              </div>
            </div>
            <div style="display: flex;align-items: center;justify-content: flex-end;margin-bottom: 20px;">
              <div>密码：</div>
              <div>
                <el-input placeholder="请输入密码" v-model="registerData.password" show-password></el-input>
              </div>
            </div>
            <div style="display: flex;align-items: center;justify-content: flex-end;margin-bottom: 20px;">
              <div>确认密码：</div>
              <div>
                <el-input placeholder="请确认密码" v-model="registerData.password2" show-password></el-input>
              </div>
            </div>
            <div style="display: flex;align-items: center;justify-content: flex-end;margin-bottom: 20px;">
              <div @click="carryZhuCe()"
                style="width: 386px;height: 45px;line-height: 45px;background-color: #E53939;color: #ffffff;border-radius: 4px;">
                立即注册
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!-- 登录 -->
    <div class="denglu" v-if="collect == '登录'">
      <div class="dengluCss">
        <div class="dengluLeft">
          <div style="margin-top: 30px;"> <img src="./assets/logo.png"
              style="width: 80px;height: 80px;border-radius: 50px;background-color: #BCC1C7;" alt="" mode="aspectFit">
          </div>
          <div style="margin-top: 50px;color: #FBB0B1;">欢迎回来</div>
          <!-- <div style="margin-top: 50px;color: #ffffff;">会员登录</div> -->
        </div>
        <div class="dengluRight">
          <div style="width: 500px;margin-bottom: 150px;">
            <div style="margin-bottom: 20px;font-size: 34px;">登录</div>
            <div style="display: flex;align-items: center;justify-content: flex-end;margin-bottom: 20px;">
              <div>手机号码：</div>
              <div>
                <el-input placeholder="请输入手机号码" v-model="loginData.phone" clearable>
                </el-input>
              </div>
            </div>
            <div style="display: flex;align-items: center;justify-content: flex-end;margin-bottom: 20px;">
              <div>密码：</div>
              <div>
                <el-input placeholder="请输入密码" v-model="loginData.password" show-password></el-input>
              </div>
            </div>
            <div style="display: flex;align-items: center;justify-content: flex-end;margin-bottom: 20px;">
              <div @click="carryDengLu()"
                style="width: 386px;height: 45px;line-height: 45px;background-color: #E53939;color: #ffffff;border-radius: 4px;">
                登录
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <!-- 个人信息 -->
    <div class="gerenxinxi" v-if="collect == '个人信息'">
      <div class="gerenxinxiCss">
        <div class="gerenxinxiLeft">
          <div style="margin-top: 30px;"> <img src="./assets/touxiang.png"
              style="width: 80px;height: 80px;border-radius: 50px;background-color: #BCC1C7;" alt="" mode="aspectFit">
          </div>
          <div style="margin-top: 20px;color: #ffffff;">{{ userData.phonenumber }}</div>
          <div style="margin-top: 10px;color: #FBB0B1;padding: 0px 10px;">注册时间：{{ userData.createTime }}</div>
          <div style="margin-top: 50px;color: #ffffff;" @click="worksType = 1">欢迎回来</div>
          <!-- <div style="margin-top: 50px;color: #ffffff;" @click="worksType = 1">会员登录</div> -->
          <div style="margin-top: 50px;color: #ffffff;" @click="worksType = 2">上传作品</div>
          <div style="margin-top: 50px;color: #ffffff;" @click="worksType = 3">我的作品</div>
        </div>
        <div class="gerenxinxiRight" v-if="worksType == 1">
          <div style="margin-top: 30px;font-size: 22px;color: #666666;font-weight: 600;">昵称：{{ userData.nickName }}
            最后登录IP：{{ userData.loginIp }}</div>
          <div style="margin-top: 30px;"><img src="./assets/touxiang.png"
              style="width: 250px;height: 250px;border-radius: 50px;background-color: #BCC1C7;" alt="" mode="aspectFit">
          </div>
          <!-- <div style="margin-top: 30px;font-size: 22px;font-weight: 300;">你还未开通任何VIP，加入VIP会员，立享8大特权</div> -->
        </div>
        <!-- 作品上传 -->
        <div class="gerenxinxiRights" v-if="worksType == 2">

          <div style="width: 500px;margin-bottom: 100px;">
            <!-- <div style="margin-bottom: 20px;font-size: 34px;">作品上传</div> -->
            <div style="display: flex;align-items: center;">
              <div style="margin-right: 10px;">类型</div>
              <el-radio v-model="radio"
                label="1">图片</el-radio>
              <el-radio v-model="radio" label="2">视频</el-radio>
            </div>
            <div style="display: flex;align-items: center;margin-top: 20px;">
             如果上传视频，请等待片刻，上传成功后，将显示【已上传】。
            </div>
            <div style="display: flex;align-items: center;margin-top: 20px;">
              <div v-if="radio=='1'" style="margin-right: 10px;">图片</div>
              <div v-else style="margin-right: 10px;">封面</div>
              <div>
                <el-upload class="avatar-uploader" action="https://api.sxmldn.com/api/common/upload"
                  :show-file-list="false" :on-success="handleAvatarSuccess">
                  <img v-if="worksData.worksImg" :src="worksData.worksImg" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon" style="line-height: 6;"></i>
                </el-upload>
              </div>
              <div v-if="radio=='2'" style="margin-right: 10px;">视频</div>
              <div v-if="radio=='2'">
                <el-upload class="avatar-uploader" action="https://api.sxmldn.com/api/common/upload"
                  :show-file-list="false" :on-success="handleAvatarSuccess222">
                  <div v-if="worksData.worksVideo" >已上传</div>
                  <i v-else class="el-icon-plus avatar-uploader-icon" style="line-height: 6;"></i>
                </el-upload>
              </div>
            </div>
            
            <div style="display: flex;align-items: center;margin-bottom: 20px;margin-top: 20px;">
              <div>作品名称：</div>
              <div>
                <el-input type="text" v-model="worksData.worksName" placeholder="请输入作品名称"></el-input>
              </div>
            </div>
            <div style="display: flex;align-items: center;margin-bottom: 20px;">
              <div>作品分类：</div>
              <div>
                <el-select v-model="worksData.genreId" placeholder="请选择作品分类">
                  <el-option v-for="item in fenleiList" :key="item.genreId" :label="item.genreName"
                    :value="item.genreId">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div style="display: flex;align-items: center;margin-bottom: 20px;">
              <div>作品标签：</div>
              <div><el-select v-model="worksData.worksLabel" placeholder="请选择作品标签">
                  <el-option v-for="item in biaoqianList" :key="item.id" :label="item.worksLabel" :value="item.id">
                  </el-option>
                </el-select></div>
            </div>
            <div style="display: flex;align-items: center;margin-bottom: 20px;">
              <div>作品描述：</div>
              <div>
                <el-input type="text" v-model="worksData.worksDescribe" placeholder="请输入作品描述"></el-input>
              </div>
            </div>
            <!-- <div style="display: flex;align-items: center;margin-bottom: 20px;">
              <div>显示顺序：</div>
              <div>
                <el-input type="number" v-model="worksData.orderNum" placeholder="请输入显示顺序"></el-input>
              </div>
            </div> -->
            <div style="display: flex;align-items: center;margin-bottom: 20px;">
              <div @click="submit()"
                style="width: 386px;height: 45px;line-height: 45px;background-color: #E53939;color: #ffffff;border-radius: 4px;">
                提交
              </div>
            </div>
          </div>

        </div>
        <div class="gerenxinxiRighta" v-if="worksType == 3">
          <div class="topView" style="">
            <div @click="viewWorks(item, item.worksId, item.worksDescribe)" v-for="(item, index) in userWorks"
              :key="index" class="topItem" style="border:1px solid rgb(250, 250, 250)">
              <div class="itema" style="">
                <el-image style="width: 100%; height: 280px" :src="item.worksImg" fit="cover"></el-image>
              </div>
              <div class="itemb" style="">{{ item.worksDescribe }}</div>
              <div class="itemc" style="display: flex;justify-content: space-between;">
                <span>
                  {{ item.reviewStatus == 0 ? '待审核' : item.reviewStatus == 1 ? '审核通过' : '审核驳回' }}
                </span>
                <span>
                  查看{{ item.worksBrowse }}
                </span>
              </div>
            </div>

          </div>
          <Pagination :pageNo="pageNum2" :pageSize="pageSize2" :total="total2" :continues="1" @getPageNo="getPageNo2" />
        </div>
      </div>
    </div>
    <!-- 关于我们 -->
    <div class="guanyu" v-if="collect == '关于'">
      <div class="guanyuCss">
        <img src="./assets/logo.png" style="width: 100%;height: 80px;" mode="aspectFit">

      </div>
      <div class="guanyudaohang">
        <div @click="xinxiCli(1)" style="margin-right: 70px;">关于我们</div>
        <div @click="xinxiCli(2)" style="margin-right: 70px;">联系我们</div>
        <div @click="xinxiCli(3)" style="margin-right: 70px;">网站简介</div>
        <div @click="xinxiCli(4)" style="margin-right: 70px;">法律条款</div>
        <div @click="xinxiCli(5)" style="margin-right: 70px;">隐私政策</div>
        <div @click="xinxiCli(6)" style="margin-right: 70px;">帮助中心</div>

      </div>
      <div class="guanyutitle" v-html="xinxiData.articleDescribe">
      </div>
    </div>
    <!-- 底层说明 -->
    <div class="appBottomCss">
      <div class="appBottoma"></div>
      <div class="appBottomb">
        <!-- <div class="appBottombView">
          <div style="margin-right: 150px;">
            <div style="margin-bottom: 20px;">关于我们</div>
            <div style="margin-bottom: 10px;">联系我们</div>
            <div style="margin-bottom: 10px;">网站简介</div>
            <div style="margin-bottom: 10px;">法律条款</div>
            <div>隐私政策</div>
          </div>
          <div style="margin-right: 150px;">
            <div style="margin-bottom: 20px;">帮助中心</div>
          </div>
          <div>
            <div style="margin-bottom: 20px;">其他</div>
            <div>站点地图</div>
          </div>
        </div> -->
        <div style="color: #969191f8;">
          <div style="margin-bottom: 10px;">本站所有刊登的各种图片及各种专题专栏资料，均为大宁网络中心版权所有，未经协议授权禁止下载使用</div>
          <div style="margin-bottom: 10px;">晋ICP备2023019893号-1 © 2023 大美大宁网 版权所有，并保留所有权利</div>
          <div>大宁县府东街17号 电话：0357-7723636</div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
// 需要添加的配置
import { postSendSMS, postRegister, postLogin, postList, postGetNursesAideInfo, postNewList, postRecommendList, postWebsiteWorks, postsimilarList, postWebsiteGenrelist, postWebsiteWorkslist, postWebsiteArticle, postAddWebsiteWorks, postMyWebsiteWorks } from "@/api/http";
import Pagination from './components/Pagination.vue'
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  name: 'HelloWorld',
  components: {
    Pagination,
    swiper,
    swiperSlide,
  },
  data() {
    return {
      // 视频播放
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], //可选择的播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: "",
          src: ''//url地址
        }],
        poster: "", //你的封面地址
        // width: document.documentElement.clientWidth,
        notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,//当前时间和持续时间的分隔符
          durationDisplay: true,//显示持续时间
          remainingTimeDisplay: false,//是否显示剩余时间功能
          fullscreenToggle: true  //全屏按钮
        }
      },
      swiperOption: {
        autoplay: true,
        speed: 300,
        loop: true,
        pagination: {
          el: ".swiper-pagination", //分页器
          clickable: true,
        },
      },
      worksType: 1,
      // 作品id
      id: '',
      loginType: 1,
      // 登录、注册、个人信息、关于、导航页面、三级页面
      collect: '导航页面',
      list: [],
      stu: 0,
      type: 1,
      homeType: 1,
      // 注册
      registerData: {
        code: "",
        nickName: "",
        password: "",
        password2: "",
        phone: "",
        uuid: "192.168.0.0"
      },
      radio: '1',
      loginData: {
        password: "",
        phone: ""
      },
      // 轮播图数据
      lunboList: [],
      // 用户信息
      userData: {},
      // 最新作品
      newWorksData: [],
      // 推荐作品
      recommendedWorksData: [],
      // 作品详情
      detailsData: {},
      // 相似作品
      xiangsiList: [],
      // 根据分类的作品
      fenleiWorksList: [],
      // 作品翻页
      total: null,
      //网站信息
      xinxiData: {},
      pageNum: 1,
      pageSize: 8,
      nameTitle: '',
      title: '',
      worksData: {
        worksImg: "",
        worksVideo: "",
        worksName: "",
        genreId: "",
        worksLabel: "",
        worksDescribe: "",
        orderNum: "",
      },
      ipAddress: '',
      // 作品分类
      fenleiList: [],
      biaoqianList: [{ worksLabel: '热门', id: "1" }, { worksLabel: '精选', id: "2" }, { worksLabel: '推荐', id: "3" },],
      userWorks: [],
      pageNum2: 1,
      pageSize2: 8,
      total2: null,
      // 验证码
      sendmsg: '发送验证码',
      isSend: true,
    };
  },
  created() {
    // this.getIPAddress()
    if (localStorage.getItem('token')) {
      // 用户信息
      this.getUser()
      this.loginType = 2
    }
    this.fenlei()
    this.newWorks()
    this.recommendedWorks()
  },
  mounted() {
    this.lunbo()
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player
    }
  },
  methods: {
    addWatermark() {
      //水印canvas
      var water_canvas = document.createElement("canvas");
      water_canvas.id = "water_canvas";
      water_canvas.height = 800;
      water_canvas.width = 800;
      water_canvas.style.top = 400 + "px";
      // water_canvas.style.left = 100 + "px";
      water_canvas.style.position = "absolute";
      water_canvas.style.transform = "rotate(0deg)";

      //水印内容
      var water_text = water_canvas.getContext("2d");
      water_text.font = "100px 黑体";
      water_text.fillStyle = "#ffffff"
      water_text.globalAlpha = 0.5
      water_text.fillText("大美大宁", 100, 300);
      //canvas容器：水印目标
      var canvas_container = document.getElementsByClassName("video-player");
      // canvas_container.style.height =  280 + "px";
      // canvas_container.style.width = 500 + "px";
      // canvas_container.style.backgroundImage = "url(bg.png)";
      //添加水印canvas给目标div
      canvas_container[0].appendChild(water_canvas);
    },


    fullScreen() {
      console.log('-12-1-22-')
      const player = this.$refs.videoPlayer.player
      player.requestFullscreen()//调用全屏api方法
      player.isFullscreen(true)
      player.play()
    },
    onPlayerPlay(player) {
      console.log('开始播放')
      player.play()
    },
    onPlayerPause(player) {
      // alert("pause");
    },
    // 作品提交
    async submit() {
      if(this.radio=='2'&&this.worksData.worksImg.length<3){
        alert('封面为必填项！')
        return
      }
      const res = await postAddWebsiteWorks(this.worksData)
      if (res.code == 200) {
        alert("上传成功");
        this.worksData = {
          worksImg: "",
          worksVideo: "",
          worksName: "",
          genreId: "",
          worksLabel: "",
          worksDescribe: "",
          orderNum: "",
        }
      }
    },
    //获取ip
    // getIPAddress() {
    //   const url = "http:/api.ipify.org?format=json";
    //   this.axios.get(url).then((response) => {
    //     this.ipAddress = response.data.ip;
    //     console.log('this.ipAddress', this.ipAddress);
    //   });
    // },
    async fenlei() {
      const res = await postWebsiteGenrelist()
      if (res.code == 200) {

        this.list = res.data
        this.list.unshift({
          ancestors: "",
          createBy: "",
          createTime: "2024-02-28 22:25:05",
          delFlag: "0",
          genreId: 1,
          genreName: "首页",
          orderNum: 1,
          parentId: 0,
          remark: null,
          updateBy: "",
          updateTime: null,
        })
        this.list.push({
          ancestors: "",
          createBy: "",
          createTime: "2024-02-28 22:25:05",
          delFlag: "0",
          genreId: 1,
          genreName: "关于我们",
          orderNum: 1,
          parentId: 0,
          remark: null,
          updateBy: "",
          updateTime: null,
        })
      }
    },
    async getPageNo2() {
      this.pageNum2 = e
      var data = {
        pageNum: this.pageNum2,
        pageSize: this.pageSize2,
      }
      const resa = await postMyWebsiteWorks(data)
      if (resa.code == 200) {
        this.userWorks = resa.rows
        this.total2 = resa.total
      }

    },
    async getPageNo(e) {
      console.log('aaa', e);
      this.pageNum = e
      var data = {
        genreId: this.genreId,
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      const res = await postWebsiteWorkslist(data)
      if (res.code == 200) {
        this.fenleiWorksList = res.rows
        this.total = res.total
      }
    },
    // 最新作品
    async newWorks() {
      var data = {}
      const res = await postNewList()
      if (res.code == 200) {
        this.newWorksData = res.data
      }
    },
    // 推荐作品
    async recommendedWorks() {
      var data = {}
      const res = await postRecommendList()
      if (res.code == 200) {
        this.recommendedWorksData = res.data
      }
    },

    // 详情作品
    async xiangqiang(id) {
      const res = await postWebsiteWorks(id)
      if (res.code == 200) {
        this.detailsData = res.data
      }
    },
    // 相似作品
    async xiangsi() {
      const res = await postsimilarList()
      if (res.code == 200) {
        this.xiangsiList = res.data
      }
    },
    // 用户信息
    async getUser() {
      var data = {}
      const res = await postGetNursesAideInfo(data)
      console.log('反馈：', res)
      if (res && res.code == 200) {
        this.userData = res.data
      } else {
        localStorage.removeItem('token')
        this.$router.go(0)
      }
    },
    // 轮播图接口
    async lunbo() {
      var data = {}
      const res = await postList(data)
      if (res.code == 200) {
        this.lunboList = res.data
      }
    },
    daning() {
      this.collect = '导航页面'
      this.homeType = 1
      this.stu = 0
    },
    async cut(index, genreId, genreName) {
      this.pageNum = 1
      this.genreId = genreId
      this.nameTitle = genreName
      console.log('this.list[index].genreName', this.list[index].genreName);
      if (this.list[index].genreName == '关于我们') {
        this.collect = '关于'
        this.xinxiCli(1)
      } else {
        var data = {
          genreId: genreId,
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
        const res = await postWebsiteWorkslist(data)
        if (res.code == 200) {
          this.fenleiWorksList = res.rows
          this.total = res.total
        }
        this.collect = '导航页面'
      }

      this.homeType = 1
      this.stu = index
      this.type = 1
    },
    // 查看作品详情
    viewWorks(item, id, worksDescribe) {

      var sources = [{
        type: "video/mp4",
        src: item.worksVideo
      }]
      this.playerOptions.sources = sources
      this.playerOptions.poster = item.worksImg
      if (item.worksVideo) {
        console.log('----', item, id, worksDescribe)

        setTimeout(() => {
          this.addWatermark()
        }, 200);
      }
      this.stu = 0
      this.collect = '导航页面'
      this.xiangqiang(id)
      this.xiangsi()
      this.title = worksDescribe
      this.homeType = 2
    },
    twoCut(item, id, title) {
      console.log('----', id, title)
      var sources = [{
        type: "video/mp4",
        src: item.worksVideo
      }]
      this.playerOptions.sources = sources
      this.playerOptions.poster = item.worksImg,
        console.log(this.playerOptions, '---')
      this.xiangqiang(id)
      this.xiangsi()
      this.title = title
      this.collect = '导航页面'
      this.type = 2

    },
    // 网站信息配置
    async xinxiCli(title) {
      const res = await postWebsiteArticle(title)
      if (res.code == 200) {
        this.xinxiData = res.data
      }
    },
    // 个人信息
    async user() {
      this.collect = '个人信息'
      const res = await postWebsiteGenrelist()
      if (res.code == 200) {
        this.fenleiList = res.data
      }
      var data = {
        pageNum: this.pageNum2,
        pageSize: this.pageSize2,
      }
      const resa = await postMyWebsiteWorks(data)
      if (resa.code == 200) {
        this.userWorks = resa.rows
        this.total2 = resa.total
      }

    },
    // 退出登录
    back() {
      this.collect = '登录'
      this.loginType = 1
      sessionStorage.removeItem("token");
    },
    // 注册
    register() {
      this.collect = '注册'
    },
    // 注册提交
    async carryZhuCe() {
      this.registerData.uuid = this.ipAddress
      if (!this.registerData.phone) {
        this.$message('请填写手机号码');
        return
      }
      if (!this.registerData.code) {
        this.$message('请填写验证码');
        return
      }
      if (!this.registerData.nickName) {
        this.$message('请填写昵称');
        return
      }
      if (!this.registerData.password) {
        this.$message('请填写密码');
        return
      }
      if (this.registerData.password != this.registerData.password2) {
        this.$message('请确认密码填写正确');
        return
      }
      const res = await postRegister(this.registerData)
      if (res.code == 200) {
        this.$message('注册成功');
        this.registerData = {
          code: "",
          nickName: "",
          password: "",
          password2: "",
          phone: "",
          uuid: "192.168.0.0"
        }
        this.collect = '登录'
      }
    },
    // 登录
    login() {
      this.collect = '登录'

    },
    // 登录提交
    async carryDengLu() {
      this.loginData.uuid = this.ipAddress
      const res = await postLogin(this.loginData)
      if (res.code == 200) {
        localStorage.setItem('token', res.data.token)
        this.type = 1
        this.collect = '导航页面'
        this.getUser()
        this.loginType = 2
        alert("登录成功");
        this.loginData = {
          password: "",
          phone: ""
        }
      }
    },
    //发送验证码
    async fasongCli() {
      console.log('!this.registerData.phone', !this.registerData.phone);
      console.log('!this.registerData.phone', this.registerData.phone);
      if (!this.registerData.phone) {
        this.$message('请填写手机号后获取验证码！');
      } else if (/^1(3|4|5|6|7|8)\d{9}$/.test(this.registerData.phone) == false) {
        this.$message('请填写正确的手机号后获取验证码！');
      } else {
        var data = {
          phone: this.registerData.phone
        }
        const res = await postSendSMS(data)
        if (res.code == 200) {
          this.$message('发送成功');
          this.isSend = false;
          let timer = 60;
          this.sendmsg = timer + "s";
          this.timeFun = setInterval(() => {
            --timer;
            this.sendmsg = timer + "s";
            if (timer == 0) {
              this.isSend = true;
              this.sendmsg = "重新发送";
              clearInterval(this.timeFun);
            }
          }, 1000);
        }
      }
    },
    // 图片
    handleAvatarSuccess(res, file) {
      this.worksData.worksImg = res.url;
    },
    beforeAvatarUpload(file) {
      console.log('file.type', file.type);

      const isJPG = file.type === 'image/png';
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      return isJPG && isLt2M;
    },
    // 视频
    handleAvatarSuccess222(res, file) {
      this.worksData.worksVideo = res.url;
    },
    beforeAvatarUpload222(file) {
      console.log('file.type', file.type);
      const ismp4 = file.type === 'video/mp4';
      if (!ismp4) {
        this.$message.error('只能上传mp4格式!');
      }
      return ismp4;
    }
  },

}
</script>
<style lang="scss">
body {
  padding: 0px;
  margin: 0px;
  background-color: #F4F5F9;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}


.appTopCss {
  background-color: #363636;
  display: flex;
  justify-content: center;
  align-items: center;

  .appTopView {
    width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;

    .appTopa {
      color: #BFBFBF;
      font-size: 14px;
    }

    .appTopb {
      color: #BFBFBF;
      font-size: 14px;

      .appTopSpana {
        margin-right: 10px;
      }

      .appTopSpanb {
        margin-left: 10px;
      }
    }
  }
}

.navigationOne {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  padding: 25px 0px;
  height: 110px;

  .oneView {
    width: 1200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.appBottomCss {
  background-color: #363636;

  .appBottoma {
    border-bottom: 1px solid #969191f8;
    padding: 14px 0px;
  }

  .appBottomb {
    padding: 60px 90px;

    .appBottombView {
      color: #969191f8;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      margin-bottom: 100px;
    }
  }
}

.txt {}

.txt:hover {
  border-bottom: 2px solid red;
  font-weight: 600;
  font-size: 18px;
}

.navigationTwo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 18px;

  .twoTop {
    width: 1200px;
    padding: 20px 0px;
    text-align: start;

    .twoTopView {
      font-size: 14px;
    }
  }

  .twoBottom {
    width: 1200px;
    background-color: #ffffff;
    padding: 20px 0px;
    text-align: start;

    .twoBottomView {
      margin-left: 20px;
      font-size: 14px;
    }
  }
}

.video-js .vjs-big-play-button {
  width: 72px;
  height: 72px;
  border-radius: 100%;
  z-index: 100;
  background-color: #ffffff;
  border: solid 1px #979797;
}

.topCss {
  display: flex;
  justify-content: center;
  align-items: center;

  .topView {
    width: 1200px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .topItem {
      width: 280px;
      margin-bottom: 20px;
      margin-right: 15px;
      background-color: #ffffff;

      .itema {
        height: 280px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .itemb {
        padding: 30px 0px 10px 0px;
        font-size: 14px;
        color: #333333;
      }

      .itemc {
        padding: 10px 10px;
        text-align: end;
        font-size: 12px;
        color: #71CD9C;
      }
    }
  }
}

.bottomCss {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  div {
    padding: 8px 16px;
    background-color: #ffffff;
    margin-right: 10px;
    font-size: 14px;
    color: #333333;
    border: 1px solid #DDDDDD;
    border-radius: 4px;
  }
}

.titCss {
  margin: 40px 0px;
  font-size: 34px;
}

.topCss {
  display: flex;
  justify-content: center;
  align-items: center;

  .topView {
    width: 1200px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .topItem {
      width: 280px;
      margin-bottom: 20px;
      margin-right: 15px;
      background-color: #ffffff;

      .itema {
        height: 280px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .itemb {
        padding: 30px 0px 10px 0px;
        font-size: 14px;
        color: #333333;
      }

      .itemc {
        padding: 10px 10px;
        text-align: end;
        font-size: 12px;
        color: #71CD9C;
      }
    }
  }
}

.threeCss {
  display: flex;
  align-items: center;
  justify-content: center;

  .threeView {
    width: 1200px;

    .threeTop {
      background-color: #ffffff;
      margin-bottom: 30px;
      padding: 30px;
    }

    .threeCenter {
      background-color: #ffffff;
      margin-bottom: 30px;
      padding: 30px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .threeBottom {
      background-color: #ffffff;
      padding: 30px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.rightulliimg {
  max-width: 100%;
  max-height: 280px;
}

.threeImg {
  max-width: 100%;
  max-height: 1000px;
}

// 注册
.zhuce {
  display: flex;
  align-items: center;
  justify-content: center;

  .zhuceCss {
    width: 1200px;
    margin: 20px 0px;
    padding: 20px;
    display: flex;
    height: 700px;

    .zhuceLeft {
      width: 240px;
      background-color: #F75F61;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;

    }

    .zhuceRight {

      // box-shadow: 0px 0px 6px -3px #302828,
      //   0px 0px 6px 0px #302828,
      //   0px 0px 5px 0px #302828,
      //   0px 0px 0px 0px #302828;
      background-color: #ffffff;
      width: 960px;
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;

      input {
        border: 1px solid #CCCCCC;
        width: 380px;
        height: 35px;
      }
    }
  }
}

// 登录
.denglu {
  display: flex;
  align-items: center;
  justify-content: center;

  .dengluCss {
    width: 1200px;
    margin: 20px 0px;
    padding: 20px;
    display: flex;
    height: 700px;

    .dengluLeft {
      width: 240px;
      background-color: #F75F61;
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;

    }

    .dengluRight {

      // box-shadow: 0px 0px 6px -3px #302828,
      //   0px 0px 6px 0px #302828,
      //   0px 0px 5px 0px #302828,
      //   0px 0px 0px 0px #302828;
      background-color: #ffffff;
      width: 960px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;

      display: flex;
      align-items: center;
      justify-content: center;

      input {
        border: 1px solid #CCCCCC;
        width: 380px;
        height: 35px;
      }
    }
  }
}

// 个人信息
.gerenxinxi {
  display: flex;
  align-items: center;
  justify-content: center;

  .gerenxinxiCss {
    width: 1200px;
    margin: 20px 0px;
    padding: 20px;
    display: flex;
    height: 700px;

    .gerenxinxiLeft {
      width: 240px;
      background-color: #F75F61;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;

    }

    .gerenxinxiRight {
      background-color: #ffffff;
      width: 960px;
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;

    }

    .gerenxinxiRighta {
      background-color: #ffffff;
      width: 960px;
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
      display: flex;
      flex-direction: column;
      align-items: center;


      .topView {
        width: 960px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        overflow: auto;
        padding-left: 10px;

        .topItem {
          width: 280px;
          margin-bottom: 20px;
          margin-right: 15px;
          background-color: #ffffff;

          .itema {
            height: 280px;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .itemb {
            padding: 30px 0px 10px 0px;
            font-size: 14px;
            color: #333333;
          }

          .itemc {
            padding: 10px 10px;
            text-align: end;
            font-size: 12px;
            color: #71CD9C;
          }
        }
      }
    }

    .gerenxinxiRights {
      background-color: #ffffff;
      width: 960px;
      border-bottom-right-radius: 4px;
      border-top-right-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;

      input {
        border: 1px solid #CCCCCC;
        width: 380px;
        height: 35px;
      }
    }
  }
}

// 关于我们
.guanyu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .guanyuCss {
    width: 100%;
  }

  .guanyudaohang {
    width: 1200px;
    margin: 20px 0px;
    padding: 20px;
    display: flex;
    align-items: center;
    background-color: #ffffff;
  }

  .guanyutitle {
    width: 1200px;
    margin: 20px 0px;
    padding: 20px;
    display: flex;
    align-items: center;
    background-color: #ffffff;
  }
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

::v-deep .avatar-uploader-icon {
  line-height: 6 !important;
}

.fasong {
  border: 1px solid red;
  position: absolute;
  width: 50px;
  right: 8px;
  top: 7px;
  background-color: red;
  color: #ffffff;
  border-radius: 6px;
}

.fasong2 {
  border: 1px solid red;
  position: absolute;
  width: 50px;
  right: 8px;
  top: 7px;
  background-color: red;
  color: #ffffff;
  border-radius: 6px;
}

/*播放按钮设置成宽高一致，圆形，居中*/
.vjs-custom-skin>.video-js .vjs-big-play-button {
  background-color: rgba(0, 0, 0, 0.45);
  font-size: 3.5em;
  border-radius: 50%;
  height: 2em !important;
  line-height: 2em !important;
  margin-top: -1em !important;
  margin-left: -1em !important;
  width: 2em !important;
  outline: none;
}

.video-js .vjs-big-play-button .vjs-icon-placeholder:before {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
}

/*control-bar布局时flex，通过order调整剩余时间的位置到进度条右边*/
.vjs-custom-skin>.video-js .vjs-control-bar .vjs-remaining-time {
  order: 3 !important;
}

/*进度条背景轨道*/
.video-js .vjs-slider {
  border-radius: 1em;
}

/*进度条进度*/
.vjs-custom-skin>.video-js .vjs-play-progress,
.vjs-custom-skin>.video-js .vjs-volume-level {
  border-radius: 1em;
}

/*鼠标进入播放器后，播放按钮颜色会变*/
.video-js:hover .vjs-big-play-button,
.vjs-custom-skin>.video-js .vjs-big-play-button:active,
.vjs-custom-skin>.video-js .vjs-big-play-button:focus {
  background-color: rgba(0, 0, 0, 0.4) !important;
}

/*control bar*/
.video-js .vjs-control-bar {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

/*点击按钮时不显示蓝色边框*/
.video-js .vjs-control-bar button {
  outline: none;
}

.watermark {
  position: absolute;
  top: 10px;
  left: 10px;
  color: white;
  font-size: 20px;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  /* 半透明背景 */
  border: 3px solid red;
}

video {
  position: relative;
  /* 确保水印在视频之上 */
  width: 100%;
}
</style>
