<template>
    <div class="home">
        <div style="height: 600px;border: 1px solid red;">
            1
        </div>
        <div class="titCss">最新作品</div>
        <div class="topCss">
            <div class="topView">
                <div v-for="(item, index) in 3" :key="index" class="topItem">
                    <div class="itema" style="">
                        <img src="../assets/logo.png" class="rightulliimg" alt="" mode="aspectFit">
                    </div>
                    <div class="itemb" style="">文字</div>
                    <div class="itemc" style="">查看47</div>
                </div>
            </div>
        </div>
        <div class="titCss">推荐作品</div>
        <div class="topCss">
            <div class="topView">
                <div v-for="(item, index) in 3" :key="index" class="topItem">
                    <div class="itema" style="">
                        <img src="../assets/logo.png" class="rightulliimg" alt="" mode="aspectFit">
                    </div>
                    <div class="itemb" style="">文字</div>
                    <div class="itemc" style="">查看47</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'HelloWorld',
    props: {
        type: String
    },
    data() {
        return {

        };
    },
    created() {

    },
    methods() {

    },
    methods: {

    }
}
</script>
<style scoped lang="scss">
.titCss {
    margin: 40px 0px;
    font-size: 34px;
}

.topCss {
    display: flex;
    justify-content: center;
    align-items: center;

    .topView {
        width: 1200px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .topItem {
            width: 280px;
            margin-bottom: 20px;
            margin-right: 15px;
            background-color: #ffffff;

            .itema {
                height: 280px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .itemb {
                padding: 30px 0px 10px 0px;
                font-size: 14px;
                color: #333333;
            }

            .itemc {
                padding: 10px 10px;
                text-align: end;
                font-size: 12px;
                color: #71CD9C;
            }
        }
    }
}</style>
  