import axios from 'axios'

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 创建axios实例
const service = axios.create({
    baseURL: 'https://api.sxmldn.com',
    timeout: 10000
})
service.interceptors.request.use(function (config) {
    if (localStorage.getItem('token')) {
        config.headers['X-token'] = localStorage.getItem('token')
    }
    return config
}, function (error) {
    return Promise.reject(error)
})
service.interceptors.response.use(function (response) {
    const code = response.data.code
    // console.log('response.data',response.data);
    if (code == 200) {
        return response.data
    } else if (code == 401) {
        alert("登录过期");
    } else {
        alert(response.data.msg);
    }
}, function (error) {
    return Promise.reject(error)
})

export default service

