import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: home
  },
  {
    path: '/handpick',
    name: 'handpick',
    component: () => import(/* webpackChunkName: "about" */ '../views/handpick.vue')
  },
  {
    path: '/economy',
    name: 'economy',
    component: () => import(/* webpackChunkName: "about" */ '../views/economy.vue')
  },
  {
    path: '/entertainment',
    name: 'entertainment',
    component: () => import(/* webpackChunkName: "about" */ '../views/entertainment.vue')
  },
  {
    path: '/ecotope',
    name: 'ecotope',
    component: () => import(/* webpackChunkName: "about" */ '../views/ecotope.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
