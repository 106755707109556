import request from '../utils/request'

// 注册短信发送
export function postSendSMS(data) {
  return request({
    url: '/api/sms/sendSMS',
    method: 'post',
    data: data
  })
}

// 注册
export function postRegister(data) {
  return request({
    url: '/api/WebsiteUser/register',
    method: 'post',
    data: data
  })
}

// 登录
export function postLogin(data) {
  return request({
    url: '/api/WebsiteUser/login',
    method: 'post',
    data: data
  })
}
// 获取用户基本信息
export function postGetNursesAideInfo(data) {
  return request({
    url: '/api/WebsiteUser/getNursesAideInfo',
    method: 'get',
    params: data
  })
}
//首页轮播图
export function postList(data) {
  return request({
    url: '/api/WebsiteRotationImg/list',
    method: 'get',
    params: data
  })
}
//最新作品
export function postNewList(data) {
  return request({
    url: '/api/WebsiteWorks/newList',
    method: 'get',
    params: data
  })
}
//推荐作品
export function postRecommendList(data) {
  return request({
    url: '/api/WebsiteWorks/recommendList',
    method: 'get',
    params: data
  })
}
// 作品详情
export function postWebsiteWorks(worksId) {
  return request({
    url: '/api/WebsiteWorks/'+worksId,
    method: 'get',
  })
}
// 相似作品
export function postsimilarList() {
  return request({
    url: '/api/WebsiteWorks/similarList',
    method: 'get',
  })
}
// 作品分类
export function postWebsiteGenrelist() {
  return request({
    url: '/api/WebsiteGenre/list',
    method: 'get',
  })
}
//根据分类查询作品

export function postWebsiteWorkslist(data) {
  return request({
    url: '/api/WebsiteWorks/list',
    method: 'get',
    params:data
  })
}
// 网站信息配置
export function postWebsiteArticle(articleType) {
  return request({
    url: '/api/WebsiteArticle/'+articleType,
    method: 'get',
  })
}
// 作品提交
export function postAddWebsiteWorks(data) {
  return request({
    url: '/api/WebsiteWorks/addWebsiteWorks',
    method: 'post',
    data:data
  })
}
//我的作品

export function postMyWebsiteWorks(data) {
  return request({
    url: '/api/WebsiteWorks/myWebsiteWorks',
    method: 'post',
    data:data
  })
}